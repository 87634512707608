


















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AutocompleteListItem extends Vue {
  @Prop(Boolean)
  active?: boolean

  @Prop(Object)
  match?: any
}
