





















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { FuseOptions } from 'fuse.js'
import AutocompleteList from './AutocompleteList.vue'
import { search } from './search-utils'

interface Match<T = any> {
  id: number
  data: T
  text: string
  html?: string
}

@Component({
  components: {
    AutocompleteList,
  },
})
export default class Autocomplete<T = any> extends Vue {
  @Prop({ type: String, required: true })
  id!: string

  @Prop({ type: String })
  value?: string

  @Prop({ type: Array, default: () => [] })
  datalist!: any[]

  @Prop({ type: Object })
  searchOptions!: FuseOptions<any>

  @Prop({ type: Function, default: (d: string) => d })
  format?: any

  @Prop({ type: String, default: '' })
  inputClass!: string

  @Prop({ type: Number, default: 7 })
  maxMatches!: number

  @Prop(String)
  placeholder?: string

  @Prop(Boolean)
  autofocus?: boolean

  @Prop(Boolean)
  highlightResults?: boolean

  @Prop(Boolean)
  loading?: boolean

  @Prop(Boolean)
  allowCustom?: boolean

  @Prop({ type: Number, default: 0 })
  minInputLength!: number

  // Data
  inputValue: string = this.value || ''
  highlightIndex: number = -1
  matches: Match<T>[] = []
  isFocused: boolean = false

  // Computed
  get query() {
    return this.inputValue.trim()
  }

  get search(): (query: string, datalist: Match<T>[]) => Match<T>[] {
    const searchFn = search<Match<T>>(
      ['text'],
      this.highlightResults,
      this.maxMatches,
      this.searchOptions,
    )
    return (query, datalist) => {
      if (!query) return datalist.slice()
      return searchFn(query, datalist)
    }
  }

  get dataFormatted() {
    return this.datalist.map((d, i) => {
      return {
        id: i,
        data: d,
        text: this.format(d),
      }
    })
  }

  // Accessibility ids
  get inputId() {
    return `autocomplete-${this.id}-input`
  }

  get listId() {
    return `autocomplete-${this.id}-listbox`
  }

  optionId(index: number) {
    return `${this.listId}-option-${index}`
  }

  get activeOptionId() {
    return this.optionId(this.highlightIndex)
  }

  get optionsVisible() {
    return this.isFocused && (this.inputValue.length >= this.minInputLength || this.loading)
  }

  reset() {
    this.inputValue = ''
    this.highlightIndex = -1
    this.matches = []
  }

  increaseIndex() {
    this.highlightIndex += 1
    if (this.highlightIndex >= this.matches.length) {
      this.highlightIndex = -1
    }
  }

  decreaseIndex() {
    if (this.highlightIndex < 0) {
      this.highlightIndex = this.matches.length
    }
    this.highlightIndex -= 1
  }

  runSearch() {
    this.matches = this.search(this.query, this.dataFormatted)
    if (this.query.length && this.matches.length) {
      this.highlightIndex = 0
    } else {
      this.highlightIndex = -1
    }
  }

  onInput(newValue: string) {
    this.inputValue = newValue
    this.$emit('input', newValue)
    this.runSearch()
  }

  onFocus() {
    this.isFocused = true
    if (this.inputValue.length >= this.minInputLength) {
      this.runSearch()
    }
  }

  @Watch('loading')
  onLoadingChanged(isLoading: boolean) {
    if (!isLoading) {
      this.runSearch()
    }
  }

  @Watch('datalist')
  onDatalistChanged() {
    this.runSearch()
  }

  onBlur(event: MouseEvent) {
    const target = event.relatedTarget as HTMLElement
    const autocompleteList = this.$refs.autocompleteList as Vue
    if (autocompleteList.$el.contains(target)) {
      return
    }
    this.isFocused = false
  }

  isHighlighted(index: number) {
    return this.highlightIndex === index
  }

  selectHighlighted() {
    this.onSelect(this.highlightIndex)
  }

  onSelect(index: number) {
    const match = this.matches[index]
    if (index < 0 && this.allowCustom) {
      this.$emit('custom', this.query)
    } else if (match) {
      this.$emit('select', match?.data)
    }
    this.reset()
  }

  onBackspace(event: KeyboardEvent) {
    if (!this.matches.length) {
      this.$emit('backspace', event)
    }
    this.reset()
  }

  onKeydown(event: KeyboardEvent) {
    // we want to use event.key here, not event.code, because sometimes users remap keys to different positions
    // fallbacks for browser compatibility
    switch (event.key) {
      case 'ArrowDown':
      case 'Down':
        // Move one down the list of suggestions
        event.preventDefault()
        this.increaseIndex()
        break
      case 'ArrowUp':
      case 'Up':
        // Move one up the list of suggestions
        event.preventDefault()
        this.decreaseIndex()
        break
      case 'Escape':
      case 'Esc':
        // send focus back to the input and reset highlight index
        this.highlightIndex = -1
        break
      case 'Backspace':
        // If there's nothing in the input, trigger the onBackspace callback
        // This can be used to do things like remove the last item in the array that we are building
        if (this.inputValue.length === 0) {
          this.onBackspace(event)
        }
        break
      case 'Enter':
        // select what's in the input
        event.preventDefault()
        event.stopPropagation()
        this.selectHighlighted()
        break
      case ',':
        // select what's in the input except the typed comma
        event.preventDefault()
        this.selectHighlighted()
        break
      default:
        break
    }
  }
}
