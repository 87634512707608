import Fuse, { FuseResult, FuseOptions } from 'fuse.js'

export const sanitize = (text: string) => text.replace(/</g, '&lt;').replace(/>/g, '&gt;')

const generateHighlightedText = (inputText: string, regions: number[][] = []) => {
  let content = ''
  let nextIndex = 0

  regions.forEach(region => {
    const lastRegionNextIndex = region[1] + 1
    const unHighlighted = sanitize(inputText.substring(nextIndex, region[0]))
    const highlighted = sanitize(inputText.substring(region[0], lastRegionNextIndex))

    content += `${unHighlighted}<b>${highlighted}</b>`
    nextIndex = lastRegionNextIndex
  })

  content += sanitize(inputText.substring(nextIndex))

  return content
}

export function highlightResult<T>({ item, matches }: FuseResult<T>): T {
  const highlightedItem = { ...item, html: '' }
  const match = matches[0]
  if (match) {
    highlightedItem.html = generateHighlightedText(match.value, match.indices)
  }
  return highlightedItem
}

export function search<T>(
  keys: (keyof T)[],
  highlighted: boolean = false,
  maxMatches: number = 7,
  fuseOptions?: FuseOptions<T>,
): (query: string, datalist: T[]) => T[] {
  const options: FuseOptions<T> = {
    keys,
    threshold: 0.25,
    includeMatches: highlighted,
    minMatchCharLength: 3,
    ...fuseOptions,
  }
  return (query: string, datalist: T[]): T[] => {
    // Highlight result text if needed
    const mapFn: (x: any) => T = highlighted ? highlightResult : match => match
    return new Fuse(datalist, options).search(query).slice(0, maxMatches).map(mapFn)
  }
}
