




































import { Component, Vue, Prop } from 'vue-property-decorator'
import AutocompleteListItem from './AutocompleteListItem.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

@Component({
  components: {
    AutocompleteListItem,
    LoadingSpinner,
  },
})
export default class AutocompleteList extends Vue {
  @Prop(String)
  id?: number

  @Prop(Number)
  highlightIndex!: number

  @Prop({ type: Array, required: true })
  matches!: any[]

  @Prop(String)
  query?: string

  @Prop(Boolean)
  loading?: boolean

  @Prop(Boolean)
  allowCustom?: boolean

  isHighlighted(index: number) {
    return index === this.highlightIndex
  }

  handleSelect(index: number) {
    this.$emit('select', index)
  }

  optionId(index: number) {
    return `${this.id}-option-${index}`
  }
}
